import { Box, VStack } from "@chakra-ui/react"
import { BlockTemplateEntityHydrated, EntityId } from "@jackfruit/common"
import { useLocation } from "@reach/router"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Masonry from "react-masonry-css"
import { useSelector } from "react-redux"
import { useDebounce, useUpdateEffect } from "react-use"
import { useBlockTemplate } from "~/hooks/useBlockTemplate"
import { useMatchTemplateTags } from "~/hooks/useMatchTemplateTags"
import { useProcessActions } from "~/hooks/useProcessActions"
import { productTemplateSelectors } from "~/redux/state/productTemplates"
import BlockTitle from "../BlockTitle"
import Pagination from "../Pagination"
import TemplateBlockFilters from "../template/TemplateBlockFilters"
import TemplateSelectButton from "../template/TemplateSelectButton"
import "./TemplateBlock.css"
import TemplateBlockLoader from "./TemplateBlockLoader"

export interface Props {
  config: BlockTemplateEntityHydrated
}

const TemplateBlockA: React.FC<Props> = ({ config }) => {
  const process = useProcessActions()
  const {
    id: blockTemplateId,
    blockId,
    tags,
    title,
    subtitle,
    isCTAButtonEnabled,
    ctaButtonText,
    itemsPerPage = 25,
    maxDesktopColumns = 5,
    minMobileColumns = 1,
  } = config

  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const templateBlockRef = useRef<HTMLDivElement>(null)

  const { search } = useLocation()
  const urlTags = new URLSearchParams(search).get("categories") ?? ""
  const initialTags = useMatchTemplateTags(urlTags)

  const breakpointColumns = {
    default: maxDesktopColumns,
    1100: 3,
    700: 2,
    500: minMobileColumns,
  }

  const {
    blockTemplate: {
      productTemplateIds = [],
      totalRemoteProducts = 0,
      isLoading = true,
    } = {},
  } = useBlockTemplate(blockTemplateId)
  const availableProductTemplateIds = useSelector(
    productTemplateSelectors.selectIds
  )

  const loadedTemplateIds = productTemplateIds.filter(id =>
    availableProductTemplateIds.includes(id)
  )

  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedTags, setSelectedTags] = useState<EntityId[]>(initialTags)

  // Watcher to change pre-selected tags based on URL query params
  useUpdateEffect(() => {
    setSelectedTags(initialTags)
  }, [search])

  const handleTagChange = useCallback((newTags: EntityId[]) => {
    setSelectedPage(0)
    setSelectedTags(newTags)
  }, [])

  const handlePageChange = useCallback((page: number) => {
    setSelectedPage(page)
    templateBlockRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [])

  useDebounce(
    () => {
      process.downloadProductTemplates({
        blockTemplateId,
        preTags: tags,
        tags: selectedTags,
        limit: itemsPerPage,
        offset: itemsPerPage * selectedPage,
      })
    },
    500,
    [selectedPage, selectedTags]
  )

  useEffect(() => {
    if (isInitialLoading) {
      if (!isLoading && loadedTemplateIds.length > 0) {
        setIsInitialLoading(false)
      }
    }
  }, [isInitialLoading, isLoading, loadedTemplateIds.length])

  if (isInitialLoading) {
    return <TemplateBlockLoader config={config} />
  }

  return (
    <Box ref={templateBlockRef}>
      <BlockTitle
        title={title}
        subtitle={subtitle}
        titleId={`template-${blockId}-title`}
        subtitleId={`template-${blockId}-subtitle`}
      />
      <TemplateBlockFilters
        blockTemplateId={blockTemplateId}
        preTags={tags}
        currentTags={selectedTags}
        onChange={handleTagChange}
      />
      <VStack position="relative">
        <Masonry
          breakpointCols={breakpointColumns}
          className="template-grid"
          columnClassName="template-grid_column"
          style={{ width: "100%" }}
        >
          {loadedTemplateIds.map(id => {
            return (
              <TemplateSelectButton
                key={id}
                blockId={blockId}
                productTemplateId={id}
                blockTemplateId={blockTemplateId}
                isCTAButtonEnabled={isCTAButtonEnabled}
                ctaButtonText={ctaButtonText}
              />
            )
          })}
        </Masonry>

        <Pagination
          itemsPerPages={itemsPerPage}
          totalItems={totalRemoteProducts}
          onPageChange={handlePageChange}
          selectedPage={selectedPage}
        />
      </VStack>
    </Box>
  )
}

export default TemplateBlockA
