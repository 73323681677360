import { EntityId } from "@jackfruit/common"
import { useDispatch, useSelector } from "react-redux"
import {
  productTemplateTags,
  productTemplateTagSelectors,
} from "~/redux/state/productTemplates"
import { RootState } from "~/redux/store"

export const useProductTemplateTag = (id: EntityId) => {
  const productTemplateTag = useSelector((state: RootState) =>
    productTemplateTagSelectors.selectById(state, id)
  )!

  return { productTemplateTag }
}

export const useProductTemplateTagActions = () => {
  const dispatch = useDispatch()

  return {
    setIsFetchingTemplates: (id: EntityId, value: boolean) => {
      dispatch(
        productTemplateTags.actions.updateOne({
          id,
          changes: {
            isFetchingTemplates: value,
          },
        })
      )
    },
  }
}
