import { useMemo } from "react"
import stringSimilarity from "string-similarity"
import { useProductTemplateTags } from "./useProductTemplateTags"

const MIN_MATCH_RATIO = 0.15

export const useMatchTemplateTags = (urlTags: string) => {
  const { productTemplateTags } = useProductTemplateTags()

  const tags = useMemo(() => {
    const compareTags = urlTags.split(",") ?? []
    const tagsWithComparisonString = productTemplateTags.map(tag => {
      return {
        compare: tag.title.toLowerCase(),
        tag,
      }
    })

    const matchingStrings: string[] = []
    const stringToCompare = tagsWithComparisonString.map(tag => tag.compare)
    const inputToCompare = compareTags.map(tag => tag.toLowerCase())

    inputToCompare.forEach(fromTag => {
      const bestMatches = stringSimilarity.findBestMatch(
        fromTag,
        stringToCompare
      )
      if (bestMatches.bestMatch.rating > MIN_MATCH_RATIO) {
        matchingStrings.push(bestMatches.bestMatch.target)
      }
    })

    const selectedTags = tagsWithComparisonString
      .filter(data => {
        return matchingStrings.includes(data.compare)
      })
      .map(data => data.tag.id)

    return selectedTags
  }, [productTemplateTags, urlTags])

  return tags
}
