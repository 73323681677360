import { BlockTemplateEntityHydrated } from "@jackfruit/common"
import React from "react"
import TemplateBlockA from "./TemplateBlockA"

interface Props {
  config: BlockTemplateEntityHydrated
}

const TemplateBlock: React.FC<Props> = ({ config }) => {
  //const { settings } = useSettings()

  // const TemplateBlockVariant =
  //   useGoogleOptimize(settings.googleOptimizeExperimentIdSeven, [
  //     TemplateBlockA,
  //     TemplateBlockB,
  //   ]) ?? TemplateBlockA

  return <TemplateBlockA config={config} />
}

export default TemplateBlock
