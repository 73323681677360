import { Button, ButtonGroup } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import sortBy from "lodash/sortBy"
import React, { useCallback, useState } from "react"
import { FiCheck } from "react-icons/fi"
import { useSelector } from "react-redux"
import { useUpdateEffect } from "react-use"
import {
  useProductTemplateTag,
  useProductTemplateTagActions,
} from "~/hooks/useProductTemplateTag"
import { productTemplateTagSelectors } from "~/redux/state/productTemplates"

export interface Props {
  blockTemplateId: EntityId
  preTags: EntityId[]
  currentTags: EntityId[]
  onChange: (tags: EntityId[]) => void
}

const TemplateBlockFilters: React.FC<Props> = ({
  blockTemplateId,
  preTags = [],
  currentTags = [],
  onChange,
}) => {
  const [tags, setTags] = useState<EntityId[]>(currentTags)

  useUpdateEffect(() => {
    setTags(currentTags)
  }, [currentTags.join(",")]) // check the string representation to avoid extra re renders

  const availableProductTemplateTags = useSelector(
    productTemplateTagSelectors.selectAll
  )
  const filteredProductTemplateTags = availableProductTemplateTags.filter(
    tag => {
      return (preTags || []).includes(tag.id)
    }
  )
  const sortedTags = sortBy(filteredProductTemplateTags, "title")
  const sortedTagIds = sortedTags.map(tag => tag.id)

  const onToggleTag = useCallback((tagId: EntityId) => {
    setTags(list => {
      const found = list.find(id => id === tagId)
      if (found) {
        return list.filter(tag => tag !== tagId)
      } else {
        return [...list, tagId]
      }
    })
  }, [])

  useUpdateEffect(() => {
    onChange(tags)
  }, [tags])

  return (
    <ButtonGroup
      display={sortedTagIds.length > 1 ? "flex" : "none"}
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      spacing={4}
      mb={6}
    >
      {sortedTagIds.map(tagId => {
        return (
          <TagButton
            key={tagId}
            tagId={tagId}
            blockTemplateId={blockTemplateId}
            isSelected={tags.includes(tagId)}
            onClick={() => onToggleTag(tagId)}
          />
        )
      })}
    </ButtonGroup>
  )
}

export default TemplateBlockFilters

interface TagButtonProps {
  tagId: EntityId
  isSelected: boolean
  blockTemplateId: EntityId
  onClick: () => void
}

const TagButton: React.FC<TagButtonProps> = ({
  tagId,
  isSelected,
  onClick,
}) => {
  const {
    productTemplateTag: { title, isFetchingTemplates },
  } = useProductTemplateTag(tagId)
  const { setIsFetchingTemplates } = useProductTemplateTagActions()
  const handleClick = useCallback(() => {
    onClick()
    if (!isSelected) {
      setIsFetchingTemplates(tagId, true)
    }
  }, [isSelected, onClick, setIsFetchingTemplates, tagId])

  return (
    <Button
      id={`tag--${tagId}`}
      leftIcon={isSelected ? <FiCheck /> : undefined}
      variant={isSelected ? "solid" : "outline"}
      onClick={handleClick}
      colorScheme={isSelected ? "primary" : "gray"}
      loadingText={title}
      isLoading={isFetchingTemplates}
      mb={4}
    >
      {title}
    </Button>
  )
}
